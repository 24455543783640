import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PostsList from "../components/posts"
import Img from "gatsby-image"

const Main = styled.main`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
  padding: ${props => props.theme.wrapper.paddingY} ${props => props.theme.wrapper.paddingX};
`

const List = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -1rem;
  margin-right: -1rem;
`

const ListItem = styled.div`
  flex: 1 1 auto;
  margin: 1rem;
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "projects" } } }
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              date
              tags
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <PostsList />
      <Main>
        <h1>Projects and Portfolio</h1>
        <hr/>
        <p>The following is a truncated list of projects I have worked on over the years. While much of my work is covered under NDA, some is not. Some of that is what is shared below.</p>
        {data.allMarkdownRemark.edges.length &&
          <List>
            {data.allMarkdownRemark.edges.map(({ node }, index) => {
              const featuredImage = node.frontmatter?.featuredImage?.childImageSharp?.fluid
              return (
                <ListItem key={index}>
                  <Img fluid={featuredImage} />
                </ListItem>
              )
            })}
          </List>
        }
      </Main>
    </Layout>
  )
}

export default IndexPage
